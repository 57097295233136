//import { environment } from "src/environments/environment";

import studyTemplateJson_ree from './../../app/constants/json-files/study-template-ree.json';
import studyTemplateJson_cancercolon from './../../app/constants/json-files/study-template-cancer-colon.json';
import studyTemplateJson_cancergastrico from './../../app/constants/json-files/study-template-cancer-gastrico.json';
import studyTemplateJson_trifasico from './../../app/constants/json-files/study-template-trifasico.json';
import studyTemplateJson_mdrtraining from './../../app/constants/json-files/study-template-mdr-training.json';

export const GlobalConstant = {
    defaultLanguage: 'en',
    studies: {
        cancercolon: {
            template: studyTemplateJson_cancercolon,
            name: 'Cancer de colon'
        }, 
        cancergastrico: {
            template: studyTemplateJson_cancergastrico,
            name: 'Cancer gástrico'
        }, 
        ree: {
            template: studyTemplateJson_ree,
            name: 'Resting Energy Expenditure'
        }, 
        mdrtraining: {
            template: studyTemplateJson_mdrtraining,
            name: 'MDR Training'
        }, 
        trifasico: {
            template: studyTemplateJson_trifasico,
            name: 'Trifásico'
        }, 
    },
    firestore: {
        collection_authorizedUsers: 'authorizedUsers',
        collection_patientId: 'patientId',
        collection_stableInfo: 'stable',
        collection_updatedInfo: 'update',
        collection_synchronizationInfo: 'synchronization',
        collection_excelInfo: 'excel',
        document_excelInfo: 'excelInfo',
    },
    formField: {
        textField: {
            maxLength: 50, 
            addmittedCharacters: /^[a-zA-Z0-9\t\n ./<>¿?;:"'`¡!@#$%^&*()\[\]{}_+=ºªñÑ,·çüÜ|\\-áéíóúÁÉÍÓÚ]*$/,
        },
    },
    study:{
        addmittedCharacters: /^[a-zA-Z0-9_]*$/,
        //studyTemplate: environment.studyTemplate,
    },
    dicom: {
        filenamesToAvoid: [ // Files to avoid
            'DICOMDIR', 
            '.DS_Store', 
            '._.DS_Store'],
        dicomKeysToKept: [ // DICOM metadata to be kept when anonymizing the images
            '_vrMap',
            'SOPClassUID',  
            'TransferSyntaxUID',
            'ImageType', 
            'Modality',
            'StudyDate',
            'SliceThickness', 
            'PixelSpacing', 
            'SeriesNumber', 
            'ImagePositionPatient',
            'Rows', 
            'Columns', 
            'BitsAllocated', 
            'BitsStored', 
            'RescaleIntercept', 
            'RescaleSlope', 
            'HighBit', 
            'PixelRepresentation', 
            'SmallestImagePixelValue', 
            'LargestImagePixelValue',
            'PhotometricInterpretation',
            'PatientSex', 
            'PatientAge', 
            'PatientWeight',
            'PatientSize',
            'PixelData',
            'SamplesPerPixel', 
            'RescaleType',
            'SpacingBetweenSlices',
            // Additional metadata to be used for software validation
            //  Adquisition parameters for software validation
            'SoftwareVersions', 'ConvolutionKernel', 'KVP', 'XRayTubeCurrent', 'ExposureTime',
            // Manufacturer parameters for software validation     
            'Manufacturer', 'ManufacturerModelName', 
            // Contrast parameters
            'ContrastBolusRoute', 'ContrastBolusAgent',
          ],
        dicomKeysToModify: [ // DICOM metadata to be modified when pseudonymizing the images
            'PatientID',
            'StudyID',
            // Unique ids for being able to manage the data --- TODO: Validate this ----
            'StudyInstanceUID', 'SeriesInstanceUID', 'SOPInstanceUID',
          ],
    },
}


     
